import React, { useEffect, useState } from "react";
import sanityClient from "./client.js";
import { saveAs } from "file-saver"; // Import FileSaver

const Sitemap = () => {
  const [sitemapData, setSitemapData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "post"]{slug}`)
      .then((data) => {
        const sitemapXML = generateSitemap(data);
        setSitemapData(sitemapXML);
        // displayXML(sitemapXML);
      })
      .catch(console.error);
  }, []);

  const generateSitemap = (posts) => {
    const baseUrl = "https://ajiteshsarangi.in";

    // Define your static routes
    const staticRoutes = [
      { url: `${baseUrl}/`, priority: "1.0" },
      { url: `${baseUrl}/services`, priority: "0.8" },
      { url: `${baseUrl}/contact`, priority: "0.7" },
      { url: `${baseUrl}/projects`, priority: "0.7" },
      { url: `${baseUrl}/privacy-policy`, priority: "0.6" },
      { url: `${baseUrl}/terms-of-use`, priority: "0.6" },
      { url: `${baseUrl}/mentorship`, priority: "0.8" },
    ];

    const dynamicRoutes = posts.map((post) => ({
      url: `${baseUrl}/blogpost/${post.slug.current}`,
      priority: "0.8",
    }));

    const allRoutes = [...staticRoutes, ...dynamicRoutes];

    // Generate XML
    const xml = `<?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${allRoutes
          .map(
            (route) => `
          <url>
            <loc>${route.url}</loc>
            <priority>${route.priority}</priority>
          </url>
        `
          )
          .join("")}
      </urlset>
    `;
    return xml;
  };

  // const displayXML = (xmlString) => {
  //   // Create a Blob of XML content
  //   const blob = new Blob([xmlString], { type: "application/xml" });
  //   const blobURL = URL.createObjectURL(blob);

  //   // Open the XML in a new tab
  //   window.open(blobURL, "_blank");
  // };

  const downloadSitemap = () => {
    if (sitemapData) {
      const blob = new Blob([sitemapData], { type: "application/xml" });
      saveAs(blob, "sitemap.xml"); // Use FileSaver to download the file
    }
  };

  if (!sitemapData) {
    return <div>Loading Sitemap...</div>;
  }

  return (
    // <div>
    //   <p>Sitemap is being displayed in a new tab.</p>
    // </div>
    <div>
      <button onClick={downloadSitemap}>Download Sitemap.xml</button>
      {/* <pre>{sitemapData}</pre> Optionally display the raw XML */}
    </div>
  );
};

export default Sitemap;
