import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const TermsOfUse = () => {
  return (
    <>
      <SEO title="Terms Of Use - Ajitesh Sarangi" metaDesc="Read our terms of use agreement outlining website rules, user responsibilities, and legal guidelines for using our services." />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Terms Of Use" page="Terms Of Use" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <h4>Acceptance of Terms</h4>
                  <p>
                    By accessing and using my portfolio website, you agree to comply with and be
                    bound by these Terms of Use.
                  </p>
                  <h4>Use of Website </h4>
                  <p>
                    You may use this website for lawful purposes only. You agree not to use the site
                    in any way that could damage, disable, or impair the site or interfere with any
                    other party's use of the site.
                  </p>
                  <h4>Disclaimer of Warranties</h4>
                  <p>
                    This website is provided "as is" without warranties of any kind, either express
                    or implied. We do not warrant that the site will be uninterrupted or error-free.
                  </p>
                  <h4>Limitation of Liability</h4>
                  <p>
                    In no event I, Ajitesh Sarangi be liable for any damages arising out of or in
                    connection with your use of the website. This includes, without limitation,
                    direct, indirect, incidental, and consequential damages.
                  </p>
                  <h4>Changes to Terms of Use</h4>
                  <p>
                    We may update these Terms of Use from time to time. We will post any changes on
                    this page, and your continued use of the website constitutes acceptance of those
                    changes.
                  </p>
                  <h4>Mentorship Program</h4>
                  <ul>
                    <li>
                      <h5>Disclaimer of Liability</h5>
                      <p>
                        By participating in this mentorship program, you acknowledge that all
                        advice, guidance, and resources provided are based on personal and
                        professional experiences of the mentors. Neither the mentors nor the program
                        organizers are liable for any decisions you make or outcomes that result
                        from your participation in the program.
                      </p>
                    </li>
                    <li>
                      <h5>No Guarantee of Results</h5>
                      <p>
                        While the mentorship program is designed to offer support and guidance, we
                        do not guarantee specific results, job placements, or career advancements.
                        Success depends on your personal effort, engagement, and application of the
                        advice provided.
                      </p>
                    </li>
                    <li>
                      <h5>Confidentiality</h5>
                      <p>
                        Any information shared during mentorship sessions should be treated as
                        confidential. Both mentors and mentees are expected to respect each other's
                        privacy and not disclose any personal or professional information to third
                        parties without consent.
                      </p>
                    </li>
                    <li>
                      <h5>Voluntary Participation</h5>
                      <p>
                        Participation in the mentorship program is entirely voluntary. You are free
                        to discontinue your involvement at any time. The mentors and program
                        organizers also reserve the right to terminate the mentorship relationship
                        if deemed necessary.
                      </p>
                    </li>
                    <li>
                      <h5>Professional Conduct</h5>
                      <p>
                        All participants are expected to maintain a professional demeanor during
                        mentorship interactions. Any form of harassment, discrimination, or
                        unprofessional behavior will not be tolerated and may result in removal from
                        the program.
                      </p>
                    </li>
                    <li>
                      <h5>Independent Decision-Making</h5>
                      <p>
                        The mentorship program provides guidance and advice, but all final decisions
                        regarding your career or educational pursuits are your own responsibility.
                        We encourage you to consider multiple perspectives and consult with other
                        professionals before making significant decisions.
                      </p>
                    </li>
                    <li>
                      <h5>Changes to the Program</h5>
                      <p>
                        The mentorship program organizers reserve the right to modify, suspend, or
                        discontinue the program at any time without prior notice. Any changes to the
                        program structure or terms will be communicated to participants promptly.
                      </p>
                    </li>
                    <h6>
                      By participating in this mentorship program, you agree to these terms and
                      conditions and acknowledge that you are responsible for your own professional
                      journey.
                    </h6>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default TermsOfUse;
