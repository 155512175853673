import React from 'react';
import { FaLightbulb, FaMoon } from 'react-icons/fa';


const SwitcherHeader = ({ toggleDarkMode, isDarkMode }) => {

    const switchColor = () => {
        document.querySelector('body').classList.toggle("active-dark-mode");
    }

    return (
        
        <button onClick={toggleDarkMode}>
      <span className={`setColor dark ${isDarkMode ? "active" : ""}`}>
        <FaLightbulb />
      </span>
      <span className={`setColor light ${!isDarkMode ? "active" : ""}`}>
        <FaMoon />
      </span>
    </button>
       
    )
}

export default SwitcherHeader;