import React from "react";

const Data = [
  {
    image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
    text: "HTML5",
  },
  {
    image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
    text: "CSS3",
  },
  {
    image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
    text: "Javascript",
  },
  {
    image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
    text: "ReactJs",
  },
  {
    image: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg",
    text: "TailwindCSS",
  },
  {
    image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
    text: "NodeJs",
  },
  {
    image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg",
    text: "Figma",
  },
  {
    image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg",
    text: "Java",
  },
  {
    image: "https://cdn.worldvectorlogo.com/logos/confluence-1.svg",
    text: "Confluence",
  },
  {
    image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg",
    text: "C++",
  },
  {
    image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg",
    text: "Firebase",
  },
  {
    image: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Jenkins_logo.svg",
    text: "Jenkins",
  },
  {
    image: "https://cdn.worldvectorlogo.com/logos/docker-4.svg",
    text: "Docker",
  },
  {
    image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg",
    text: "Redux",
  },
  {
    image: "https://cdn.worldvectorlogo.com/logos/jira-1.svg",
    text: "Jira",
  },
];

const BrandItem = () => {
  return (
    <>
      {Data.map((data, index) => (
        <div className="col-lg-3 col-6" key={index}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100px",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + data.image}
              alt="Brand"
              style={{ height: "130px", width: "90px" }}
            />
            <h5
              style={{
                color: "rgb(123 137 168/1)",
                opacity: "0.8",
                fontWeight: "700",
                fontFamily: "Poppins",
              }}
            >
              {data.text}
            </h5>
          </div>
        </div>
      ))}
    </>
  );
};

export default BrandItem;
