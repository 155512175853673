import React, { useState, useEffect } from "react";
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import PortableText from "react-portable-text";
import { useParams } from "react-router-dom";
import {
  FaFacebookF,
  FaTelegramPlane,
  FaLinkedin,
  FaInstagram,
  FaGithub,
  FaSpotify,
  FaSnapchatGhost,
} from "react-icons/fa";
import { BsTwitterX, BsThreads } from "react-icons/bs";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}
const BlogAuthor = () => {
  // sanity ------
  const [Post, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            content,
            "name": author->name,
            "authorImage": author->image,
            "bio":author->bio,
            "socialMediaLinks": author->socialMediaLinks
        }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!Post) return <div>Loading...</div>;
  const socialLinks = Post.socialMediaLinks || {};
  
  // sanity ------
  return (
    <div className="blog-author">
      <div className="author">
        <div className="author-thumb">
          <img src={urlFor(Post.authorImage).url()} alt="Blog Author" />
        </div>
        <div className="info">
          <h5 className="title">{Post.name}</h5>
          <p>
            <PortableText
              content={Post.bio}
              projectId="u91eo98w"
              dataset="production"
              serializers={{
                h1: (props) => <h1 style={{ color: "red" }} {...props} />,
                li: ({ children }) => (
                  <li className="special-list-item">{children}</li>
                ),
              }}
            />
          </p>
          <ul className="social-share list-unstyled">
          {socialLinks.facebook && (
              <li>
                <a href={socialLinks.facebook}>
                  <FaFacebookF />
                </a>
              </li>
            )}
            {socialLinks.twitter && (
              <li>
                <a href={socialLinks.twitter}>
                  <BsTwitterX />
                </a>
              </li>
            )}
            {socialLinks.spotify && (
              <li>
                <a href={socialLinks.spotify}>
                  <FaSpotify />
                </a>
              </li>
            )}
            {socialLinks.linkedin && (
              <li>
                <a href={socialLinks.linkedin}>
                  <FaLinkedin />
                </a>
              </li>
            )}
            {socialLinks.instagram && (
              <li>
                <a href={socialLinks.instagram}>
                  <FaInstagram />
                </a>
              </li>
            )}
            {socialLinks.github && (
              <li>
                <a href={socialLinks.github}>
                  <FaGithub />
                </a>
              </li>
            )}
            {socialLinks.threads && (
              <li>
                <a href={socialLinks.threads}>
                  <BsThreads />
                </a>
              </li>
            )}
            {socialLinks.telegram && (
              <li>
                <a href={socialLinks.telegram}>
                  <FaTelegramPlane />
                </a>
              </li>
            )}
            {socialLinks.snapchat && (
              <li>
                <a href={socialLinks.snapchat}>
                <FaSnapchatGhost />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BlogAuthor;
