import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import TestimonialPropOne from "./TestimonialPropOne";
import { motion } from "framer-motion";
const TestimonialOne = () => {
  return (
    <div className="section section-padding">
      <div className="container">
        <motion.div
          animate={{ opacity: 1, transition: { duration: 2, delay: 1.2 } }}
          initial={{ opacity: 0 }}
        >
          <SectionTitle
            subtitle="Testimonial"
            title="Why people love my work!"
            description="I am dedicated to delivering high-quality results that meet the specific needs and goals of my clients. I take the time to understand their vision and work closely with them to bring it to life. I am also highly skilled in my field and use the latest tools and techniques to ensure that my work is always of the highest standard."
            textAlignment="heading-left"
            textColor=""
          />
        </motion.div>

        <div className="row">
          <TestimonialPropOne colSize="col-lg-4" itemShow="3" />
        </div>
      </div>
      <ul className="shape-group-4 list-unstyled">
        <li className="shape-1">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" />
        </li>
      </ul>
    </div>
  );
};

export default TestimonialOne;
