import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { flatDeep, slugify, containsObject } from "../../../utils";
import BlogData from "../../../data/blog/BlogData.json";
import sanityClient from "../../../client.js";

const WidgetCategory = () => {
  // sanity ----
  const [postData, setPostData] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="post"] | order(publishedAt desc){
        title,
        slug,
        content,
        mainImage{
                asset->{
                    _id,
                    url
                }
            },
        "name": author->name,
        "authorImage": author->image,
        publishedAt,
        category
    }`
      )
      .then((data) => {
        setPostData(data);
        // Extract unique categories from post data
        const uniqueCategories = [
          ...new Set(data.map((post) => post.category).filter(Boolean)),
        ];
        setCategories(uniqueCategories);
      })
      .catch(console.error);
  }, []);

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  // Function to handle click and scroll to top
  const handleCategoryClick = (categoryUrl) => {
    navigate(categoryUrl);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
  };

  return (
    <ul className="category-list list-unstyled">
      <li>
        <Link to="/blogpost" onClick={() => handleCategoryClick("/blogpost")}>
          All Categories
        </Link>{" "}
        {/* Hardcoded link for all categories */}
      </li>
      {categories.map((category, index) => (
        <li key={index}>
          <Link
            to={`/blogpost?category=${encodeURIComponent(category)}`}
            onClick={() =>
              handleCategoryClick(
                `/blogpost?category=${encodeURIComponent(category)}`
              )
            }
          >
            {capitalizeFirstLetter(category)}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default WidgetCategory;
