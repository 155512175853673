import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import BlogListOne from "../../component/blog/BlogListOne";
import { motion } from "framer-motion";
const BlogOne = () => {
  return (
    <div className="section section-padding-equal">
      <div className="container">
        <motion.div
          animate={{ opacity: 1, transition: { duration: 2, delay: 1.2 } }}
          initial={{ opacity: 0 }}
          viewport={{ once: false, amount: 0.25 }}
        >
          <SectionTitle
            subtitle="What's Going On"
            title="Latest stories"
            description="Hello World! I love sharing my thoughts and insights on all the little things that make life enjoyable, whether it’s savoring the perfect cup of coffee, diving into a great movie, or exploring health tips."
            textAlignment=""
            textColor=""
          />
        </motion.div>

        <div className="row g-0">
          <BlogListOne colSize="col-xl-6" itemShow="2" />
        </div>
      </div>
      <ul className="shape-group-1 list-unstyled">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="bubble" />
        </li>
        <li className="shape shape-2">
          <img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="bubble" />
        </li>
        <li className="shape shape-3">
          <img src={process.env.PUBLIC_URL + "/images/others/line-2.png"} alt="bubble" />
        </li>
        <li className="shape shape-4">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="bubble" />
        </li>
      </ul>
    </div>
  );
};

export default BlogOne;
