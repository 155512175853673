import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import WidgetPost from "./widget/WidgetPost";
import WidgetCategory from "./widget/WidgetCategory";
import {
  FaFacebookF,
  FaTelegramPlane,
  FaLinkedin,
  FaInstagram,
  FaGithub,
  FaSpotify,
  FaSearch,
  FaTimes,
} from "react-icons/fa";
import { BsTwitterX, BsThreads } from "react-icons/bs";

const BlogSidebar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate(); // Initialize navigate
  const location = useLocation();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent form submission
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (searchTerm.trim()) {
      // Navigate to BlogGrid with the search term in query string
      navigate(`/blogpost?search=${encodeURIComponent(searchTerm)}`);
    }
  };
  const clearSearch = () => {
    setSearchTerm(""); // Clear the search term
    navigate(`/blogpost`); // Navigate to the main blog page without search query
  };
  useEffect(() => {
    setSearchTerm(""); // Clear search term when navigating to any page
  }, [location]);
  return (
    <div className="axil-sidebar">
      <div className="widget widget-search">
        <h4 className="widget-title">Search</h4>
        <form className="blog-search" onSubmit={handleSearchSubmit}>
          <input
            type="text"
            placeholder="Search…"
            value={searchTerm}
            onChange={handleSearchChange}
          />

          <button className="search-button" type="submit">
            <FaSearch />
          </button>
          <button className="cross-button" onClick={clearSearch}>
            <FaTimes />
          </button>
        </form>
      </div>
      <div className="widget widget-categories">
        <h4 className="widget-title">Categories</h4>
        <WidgetCategory />
      </div>
      <div className="widget widge-social-share">
        <div className="blog-share">
          <h5 className="title">Follow:</h5>
          <ul className="social-list list-unstyled">
            <li>
              <a href="https://www.facebook.com/ajitesh.sarangi.3">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="https://open.spotify.com/user/312b65gwzyjj5zijya2qxooealqi?si=53ad48ad72eb4dc1">
                <FaSpotify />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/ajitesh-sarangi-9a41a1219/">
                <FaLinkedin />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/ajitesh.sarangi/">
                <FaInstagram />
              </a>
            </li>
            <li>
              <a href="https://github.com/ajiteshsarangi">
                <FaGithub />
              </a>
            </li>
            <li>
              <a href="https://t.me/ajiteshsarangi">
                <FaTelegramPlane />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="widget widget-recent-post">
        <h4 className="widget-title">Recent post</h4>
        <WidgetPost />
      </div>
    </div>
  );
};

export default BlogSidebar;
