import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import BrandItem from "./BrandItem";
import { motion } from "framer-motion";
const BrandOne = () => {
  return (
    <div className="section section-padding-2 bg-color-dark">
      <div className="container">
        <motion.div
          animate={{ opacity: 1, transition: { duration: 2, delay: 1.2 } }}
          initial={{ opacity: 0 }}
        >
          <SectionTitle
            subtitle="Tech Stacks"
            title="The technologies I use..."
            description="Design anything from simple icons to fully featured websites and applications."
            textAlignment="heading-light-left"
            textColor=""
          />
        </motion.div>

        <div className="row">
          <BrandItem />
          <div className="col-lg-3 col-6">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100px",
              }}
            >
              <img
                src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original-wordmark.svg"
                alt="Brand"
                style={{
                  height: "130px",
                  width: "90px",
                  filter: "invert(1)",
                }}
              />
              <h5
                style={{
                  color: "rgb(123 137 168/1)",
                  opacity: "0.8",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                }}
              >
                NextJs
              </h5>
            </div>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" />
        </li>
      </ul>
    </div>
  );
};

export default BrandOne;
