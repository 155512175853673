import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import CounterUpTwo from "../component/counterup/CounterUpTwo";
import TeamOne from "../component/team/TeamOne";
import VideoTwo from "../component/video/VideoTwo";

const Team = () => {
  return (
    <>
      <SEO title="Mentorship Program - Ajitesh Sarangi" metaDesc="Dedicated to guiding and inspiring my peers. Discover my mentorship program and learn how I can help you reach your goals." />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="Meet Industry Experienced Professionals"
          paragraph="Connect with seasoned experts who bring years of hands-on experience in their respective fields. Our program offers you the unique opportunity to learn directly from industry leaders who have successfully navigated the challenges and trends of their sectors."
          styleClass="thumbnail-2"
          mainThumb="/images/banner/undraw_connected_re_lmq2.svg"
        />
        <CounterUpTwo />
        <TeamOne />
        <VideoTwo />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Team;
