import React from "react";
import FormOne from "../contact/FormOne";
import { motion } from "framer-motion";
// import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
const AboutOne = () => {
  return (
    <section className="section section-padding-equal ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <motion.span
                  animate={{
                    opacity: 1,
                    transition: { duration: 0.5, delay: 0.4 },
                  }}
                  initial={{ opacity: 0 }}
                  className="subtitle"
                >
                  About Me
                </motion.span>
                <motion.h2
                  animate={{
                    opacity: 1,
                    transition: { duration: 1.2, delay: 0.7 },
                  }}
                  initial={{ opacity: 0 }}
                  className="title mb--40"
                >
                  I do design, code &amp; develop.
                </motion.h2>
                <motion.p
                  animate={{
                    opacity: 1,
                    transition: { duration: 1.5, delay: 0.7 },
                  }}
                  initial={{ opacity: 0 }}
                >
                  Welcome to my portfolio website! Here, I showcase my expertise
                  in web design and development, graphic design, digital
                  marketing, and branding. With a wealth of experience across
                  various industries, I take pride in understanding diverse
                  needs and crafting unique solutions.
                </motion.p>
                <motion.p
                  animate={{
                    opacity: 1,
                    transition: { duration: 1.5, delay: 0.7 },
                  }}
                  initial={{ opacity: 0 }}
                >
                  Whenever I learn something new, I love to share my insights
                  through blogging—from making the perfect espresso and skincare
                  tips to health insights and the latest in technology. I
                  believe in the power of collaboration and the importance of
                  building connections that foster creativity and innovation.
                  Feel free to explore my work and writings, and reach out if
                  you’d like to discuss ideas or projects. I look forward to
                  connecting with you!
                </motion.p>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box">
              <h3 className="title">Connect with Me!</h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
            alt="line"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="line"
          />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
