import React from "react";
import { Link } from "react-router-dom";
import CounterUp from "./CounterUp";

const CounterUpTwo = () => {
  return (
    <div className="section section-padding expert-counterup-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="section-heading heading-left">
              <span className="subtitle">Experts in field</span>
              <h2>
                Our services are completely <b style={{ color: "#5956E9" }}>FREE</b> of charge!
              </h2>
              <p className="mb--50">
                We stand out by offering personalized mentorship that’s tailored to your individual
                career goals. Our program connects you with industry professionals who are not only
                experts in their fields but also passionate about helping you succeed.
              </p>
              <Link
                style={{ width: "262px" }}
                to={process.env.PUBLIC_URL + "/contact"}
                className="axil-btn btn-large btn-fill-primary"
              >
                Get Free Mentorship
              </Link>
            </div>
          </div>
          <div className="col-lg-6 offset-xl-1">
            <div className="row">
              <CounterUp colSize="col-sm-6" layoutStyle="counterup-style-2" evenTopMargin="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterUpTwo;
