import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaAngleRight,
  FaAngleLeft,
  FaArrowRight,
  FaArrowLeft,
} from "react-icons/fa";
import Slider from "react-slick";
import ReactPaginate from "react-paginate";
import sanityClient from "./client.js";
import imageUrlBuilder from "@sanity/image-url";
import PortableText from "react-portable-text";
import { TailSpin } from "react-loader-spinner";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const BlogGridOne = ({ searchTerm, category }) => {
  // Move all useState calls to the top level
  const [postData, setPostData] = useState([]);
  const [toggler, setToggler] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

    // Fetch data from Sanity
  useEffect(() => {
    setIsLoading(true);
    sanityClient
      .fetch(
        `*[_type=="post"] | order(publishedAt desc){
        title,
        slug,
        content,
        mainImage{
                asset->{
                    _id,
                    url
                }
            },
        "name": author->name,
        "authorImage": author->image,
        publishedAt,
        category
    }`
      )
      .then((data) => {
        setPostData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);


  // Loading state
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <TailSpin
          visible={true}
          height="80"
          width="80"
          color="#5956E9"
          ariaLabel="tail-spin-loading"
          radius={1}
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  const formatDate = (s) => {
    const date = new Date(s);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(date);
  };

  const formatTime = (s) => {
    const date = new Date(s);
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "Asia/Kolkata",
    }).format(date); // Example: "12:30 PM"
  };

  // ================================

  function SlickNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  }

  function SlickPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  }

  var slideSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
  };

  const blogsPerPage = 4;
  const pageVisited = pageNumber * blogsPerPage;

  // const filteredPosts = postData.filter((post) =>
  //   post.title.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const filteredPosts = postData.filter((post) => {
    const matchesSearch = post.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCategory = category ? post.category === category : true; // Check if category matches
    return matchesSearch && matchesCategory; // Combine both filters
  });

  // const pageCount = Math.ceil(postData.length / blogsPerPage);
  const pageCount = Math.ceil(filteredPosts.length / blogsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      {filteredPosts &&
        filteredPosts
          .slice(pageVisited, pageVisited + blogsPerPage)
          .map((data) => (
            <div className="blog-grid" key={data.title}>
              <h3 className="title">
                <a href="#">{data.title}</a>
              </h3>
              <div className="author">
                <div className="author-thumb">
                  <img
                    style={{ height: "100px", borderRadius: " 50%" }}
                    src={urlFor(data.authorImage).url()}
                    alt="Blog Author"
                  />
                </div>
                <div className="info">
                  <h6 className="author-name">
                    <div>{data.name}</div>
                  </h6>
                  <ul className="blog-meta list-unstyled">
                    <li>{formatDate(data.publishedAt)}</li>
                    <li>{formatTime(data.publishedAt)}</li>
                  </ul>
                </div>
              </div>

              <div className="post-thumbnail">
                <Slider className="slick-arrow-nav">
                  <div className="slide-item">
                    <img
                      src={urlFor(data.mainImage.asset.url).url()}
                      alt="Blog"
                    />
                  </div>
                </Slider>
              </div>
              <p
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  webkitLineClamp: "5",
                  webkitBoxOrient: "vertical",
                  height: "105px",
                }}
              >
                <PortableText
                  content={data.content}
                  projectId="u91eo98w"
                  dataset="production"
                  serializers={{
                    h1: (props) => <h1 style={{ color: "red" }} {...props} />,
                    li: ({ children }) => (
                      <li className="special-list-item">{children}</li>
                    ),
                  }}
                />
              </p>

              <Link
                className="axil-btn btn-borderd btn-large"
                to={process.env.PUBLIC_URL + "/blogpost/" + data.slug.current}
                key={data.slug.current}
                // to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}
              >
                Read More
              </Link>
            </div>
          ))}

      <ReactPaginate
        previousLabel={<FaArrowLeft />}
        nextLabel={<FaArrowRight />}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"pagination justify-content-start"}
        previousLinkClassName={"prev"}
        nextLinkClassName={"next"}
        disabledClassName={"disabled"}
        activeClassName={"current"}
      />
    </>
  );
};

export default BlogGridOne;
