import React from "react";
import { Link } from "react-router-dom";
import ServiceData from "../../data/service/WorkExp.json";
import { slugify } from "../../utils";

const AllData = ServiceData;

const ServicePropOne = ({ colSize, serviceStyle, itemShow, marginTop }) => {
  const topMargin = (index) => {
    if (marginTop === "yes") {
      if (index === 0) {
        // return "mt--200 mt_md--0";
        return "mt--0";
      } else if (index === 1) {
        // return "mt--100 mt_md--0";
        return "mt--0";
      } else {
        return "mt--0";
      }
    } else {
      return "";
    }
  };

  return (
    <>
      {AllData.slice(0, itemShow).map((data, index) => (
        <div className={`${colSize} ${topMargin(index)}`} key={index}>
          <div className={`services-grid ${serviceStyle}`} style={{ height: "23rem" }}>
            {/* <div className="thumbnail">
              <img src={process.env.PUBLIC_URL + data.image} alt="icon" />
            </div> */}
            <div className="content">
              <h5 className="title">
                <img
                  src={process.env.PUBLIC_URL + data.image}
                  alt="icon"
                  style={{ width: "220px", margin: data.margin }}
                />
                <div style={{ color: "grey" }}>{data.title}</div>
              </h5>
              <p style={{ webkitLineClamp: "8" }}>{data.description}</p>
              {/* <Link to={process.env.PUBLIC_URL + "/services"} className="more-btn">
                Find out more
              </Link> */}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ServicePropOne;
