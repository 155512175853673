import React, { useState, useEffect } from "react";
import sanityClient from "../../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import PortableText from "react-portable-text";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const PropOne = () => {
  // sanity ----
  const [postData, setPostData] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="project"]{
        projectTitle,
        link,
        content,
        projectImage{
                asset->{
                    _id,
                    url
                }
            },
    }`
      )
      .then((data) => {
        setPostData(data);
      })
      .catch(console.error);
  }, []);
  // sanity ends ----
  return (
    <>
      {postData.map((portfolio) => (
        <div className="project-grid projectStyle">
          <div className="thumbnail">
            <a href={portfolio.link}>
              <img src={urlFor(portfolio.projectImage.asset.url).url()} alt="icon" />
            </a>
          </div>
          <div className="content">
            <h4 className="title">{portfolio.projectTitle}</h4>
            <span className="subtitle">
              <div>
                <PortableText
                  content={portfolio.content}
                  projectId="u91eo98w"
                  dataset="production"
                  serializers={{
                    h1: (props) => <h1 style={{ color: "red" }} {...props} />,
                    li: ({ children }) => <li className="special-list-item">{children}</li>,
                  }}
                />
              </div>
            </span>
          </div>
        </div>
      ))}
    </>
  );
};

export default PropOne;
