import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import PortableText from "react-portable-text";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}
const WidgetPost = () => {

  // =============== Sanity =========================
  const [postData, setPostData] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="post"] | order(publishedAt desc){
        title,
        slug,
        content,
        mainImage{
                asset->{
                    _id,
                    url
                }
            },
        "name": author->name,
        "authorImage": author->image,
        publishedAt
    }`
      )
      .then((data) => {
        setPostData(data);
      })
      .catch(console.error);
  }, []);
  // =======================================


// =============  Date & Time & Reading Time  ===================
// Convert date and time
const formatDate = (s) => {
  const date = new Date(s);
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(date); // Example: "Feb 12, 2017"
};

const formatTime = (s) => {
  const date = new Date(s);
  return new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  }).format(date); // Example: "12:30 PM"
};

// ================================



  return (
    <div className="post-list-wrap">
      {postData.slice(0, 20).map((data, index) => (
        <div className="single-post" key={index}>
          <div className="post-thumbnail">
            <Link to={process.env.PUBLIC_URL + "/blogpost/" + data.slug.current}>
              <img src={urlFor(data.mainImage.asset.url).url()} alt="Blog" />
            </Link>
          </div>
          <div className="post-content">
            <h6 className="title">
              <Link to={process.env.PUBLIC_URL + "/blogpost/" + data.slug.current}>
                {data.title}
              </Link>
            </h6>
            <ul className="blog-meta list-unstyled">
            <li>{formatDate(data.publishedAt)}</li>
            <li>{formatTime(data.publishedAt)}</li>
            
            </ul>
            {/* <ul className="blog-meta list-unstyled">
              <li>{data.publishedAt.slice(0, 10)}</li>
              <li>{chnz(data.publishedAt).slice(9, 26)}</li>
            </ul> */}
            {/* <p
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                webkitLineClamp: "2",
                webkitBoxOrient: "vertical",
              }}
            >
              <PortableText
                content={data.content}
                projectId="u91eo98w"
                dataset="production"
                serializers={{
                  h1: (props) => <h1 style={{ color: "red" }} {...props} />,
                  li: ({ children }) => <li className="special-list-item">{children}</li>,
                }}
              />
            </p> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WidgetPost;
