import React from "react";
import { FaEnvelopeOpen, FaTimes } from "react-icons/fa";
import "./modal.css";

const Modal = ({ closeModal }) => {
    const [result, setResult] = React.useState("");

    const onSubmit = async (event) => {
        // event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);
    
        formData.append("access_key", "91023bf5-cd18-4a3e-81cc-aa8b5475a375");
    
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData
        });
    
        const data = await response.json();
    
        if (data.success) {
          setResult("Form Submitted Successfully");
          event.target.reset();
        } else {
          console.log("Error", data);
          setResult(data.message);
        }
      };
    
    
  const handleOutsideClick = (e) => {
    if (e.target.className === "modal-overlay") closeModal();
  };

  return (
    <div className="modal-overlay" onClick={handleOutsideClick}>
      <div className="modal-content">
        <button className="close-button" onClick={closeModal}>
          <FaTimes />
        </button>
        <div className="footer-newsletter">
          <h2 className="title" style={{ color: "white" }}>
            Subscribe
          </h2>
          <p style={{ color: "beige" }}>
            Join the community and be the first to know about my latest blogs,
            projects, and promotions.
          </p>
          <form
            

            onSubmit={onSubmit}

          >
            
            <div
              className="input-group"
              style={{ backgroundColor: "#212121", flexWrap: "nowrap" }}
            >
              {/* <span className="mail-icon" style={{ color: "white" }}>
                <FaEnvelopeOpen />
              </span> */}

              <input
                style={{ color: "white" }}
                type="email"
                name="email"
                className="form-control"
                placeholder="Email address"
                required
              />
              <button className="subscribe-btn" type="submit">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
