import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import PortableText from "react-portable-text";
const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}
const BlogListOne = ({ colSize, itemShow }) => {
  // sanity ----
  const [postData, setPostData] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="post"] | order(publishedAt desc){
        title,
        slug,
        content,
        mainImage{
                asset->{
                    _id,
                    url
                }
            },
        "name": author->name,
        "authorImage": author->image,
        publishedAt
    }`
      )
      .then((data) => {
        setPostData(data);
      })
      .catch(console.error);
  }, []);

  // sanity ends ----
  return (
    <>
      {postData.slice(0, itemShow).map((data) => (
        <div className={`${colSize}`} key={data.id}>
          <div className={`blog-list ${data.id % 2 === 0 ? "border-start" : ""}`}>
            <div className="post-thumbnail">
              <div>
                <img
                  style={{ height: "100px", borderRadius: "50%" }}
                  src={urlFor(data.mainImage.asset.url).url()}
                  alt="Blog Post"
                />
              </div>
            </div>
            <div className="post-content">
              <h5 className="title">
                <div>{data.title}</div>
              </h5>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  webkitLineClamp: "5",
                  webkitBoxOrient: "vertical",
                  height: "100px",
                }}
              >
                <PortableText
                  content={data.content.slice(0, 30)}
                  projectId="u91eo98w"
                  dataset="production"
                  serializers={{
                    h1: (props) => <h1 style={{ color: "red" }} {...props} />,
                    li: ({ children }) => <li className="special-list-item">{children}</li>,
                  }}
                />
              </div>

              <Link
                to={process.env.PUBLIC_URL + "/blogpost/" + data.slug.current}
                key={data.slug.current}
                className="more-btn"
              >
                Learn more <FaAngleRight />
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default BlogListOne;
