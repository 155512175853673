import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TeamData from "../../data/team/TeamData.json";
import imageUrlBuilder from "@sanity/image-url";
import PortableText from "react-portable-text";
import sanityClient from "../../client.js";
import { slugify } from "../../utils";

const allData = TeamData;

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const TeamOne = () => {
  // sanity ----
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="team"]{
        id,
        name,
        profileImage{asset->{url}},
        designation,
        linkedinLink
      }`
      )
      .then((data) => {
        setTeamData(data);
      })
      .catch(console.error);
  }, []);

  // sanity ends ----

  return (
    <div className="section section-padding bg-color-dark pb--70 pb_lg--20 pb_md--0">
      <div className="container">
        <div className="section-heading heading-light">
          <span className="subtitle">Our Values</span>
          <h2 className="title mb--50">Meet The Team</h2>
          <p>
            Meet the dedicated professionals who are passionate about guiding you to success,
            bringing together diverse expertise and a shared commitment to your growth.
          </p>
        </div>
        <div className="row">
          {teamData.map((data) => (
            <div className="col-xl-3 col-sm-6" key={data.id}>
              <div className="team-grid">
                <div className="thumbnail">
                  {data.linkedinLink ? (
                    <a href={data.linkedinLink}>
                      <img src={urlFor(data.profileImage).url()} alt={data.name} />
                    </a>
                  ) : (
                    <Link to="#">
                      <img src={urlFor(data.profileImage).url()} alt={data.name} />
                    </Link>
                  )}
                </div>
                <div className="content">
                  <h4 className="title">
                    <Link to="#">{data.name}</Link>
                  </h4>
                  <span
                    className="designation"
                    dangerouslySetInnerHTML={{ __html: data.designation }}
                  ></span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" />
        </li>
        <li className="shape shape-2">
          <img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" />
        </li>
        <li className="shape shape-3">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" />
        </li>
      </ul>
    </div>
  );
};

export default TeamOne;
