import React, { useState } from "react";
import ProjectPropOne from "./itemProp/ProjectPropOne";
import ProjectData from "../../data/project/ProjectData.json";

const AllData = ProjectData;

const ProjectFive = () => {
  return (
    <div className={`section section-padding-equal pt--200 pt_md--80 pt_sm--60 `}>
      <div className="container-fluid plr--30">
        <div className={`row row-cols-1 row-cols-sm-2 row-cols-xl-3`}>
          <ProjectPropOne />
        </div>
      </div>
    </div>
  );
};

export default ProjectFive;
