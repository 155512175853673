import React, { useState, useEffect } from "react";
import sanityClient from "../../client.js";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";

const BannerOne = () => {
  // sanity ----
  const [postData, setPostData] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="resume"]{
        link,
    }`
      )
      .then((data) => {
        setPostData(data);
      })
      .catch(console.error);
  }, []);
  // sanity ends ----
  // console.log(postData[0].link);
  return (
    <>
      {postData.map((ele) => (
        <div className="banner banner-style-1">
          <div className="container">
            <div className="row align-items-end align-items-xl-start">
              <div className="col-lg-6">
                <div className="banner-content">
                  <AnimationOnScroll
                    animateIn="slideInUp"
                    duration={0}
                    animateOnce={true}
                    delay={0}
                  >
                    <span className="subtitle">Hey, I'm Ajitesh.</span>
                  </AnimationOnScroll>
                  <AnimationOnScroll
                    animateIn="fadeInUp"
                    animateOnce={true}
                    delay={0}
                  >
                    <h1 className="title">
                      I enjoy{" "}
                      <u style={{ textDecorationColor: "blue" }}>building</u>{" "}
                      and &amp; designing for the web.{" "}
                    </h1>

                    <a
                      download="Ajitesh_Sarangi_Resume"
                      // href={process.env.PUBLIC_URL + "/images/Ajitesh_Resume@9.2.pdf"}
                      href={ele.link}
                      className="axil-btn btn-fill-primary btn-large"
                    >
                      Download Resume
                    </a>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="banner-thumbnail">
                  <AnimationOnScroll
                    animateIn="zoomIn"
                    duration={0.5}
                    delay={0}
                    animateOnce={true}
                  >
                    <div>
                      <ul className="list-unstyled shape-group-21">
                        <li className="shape-texture">
                          <img
                            style={{ position: "relative", zIndex: "-10" }}
                            src={
                              process.env.PUBLIC_URL +
                              "/images/others/bubble-g.png"
                            }
                            alt="Laptop"
                          />
                        </li>
                      </ul>
                    </div>
                  </AnimationOnScroll>
                  <AnimationOnScroll
                    animateIn="slideInRight"
                    duration={1}
                    delay={50}
                    animateOnce={true}
                  >
                    <div className="large-thumb-2">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/banner/working_1.svg"
                        }
                        alt="Laptop"
                      />
                    </div>
                  </AnimationOnScroll>
                  <ul className="list-unstyled shape-group">
                    <li className="shape shape-1">
                      <AnimationOnScroll
                        animateIn="slideInLeft"
                        duration={0}
                        delay={0}
                        animateOnce={true}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/banner/chat-group.png"
                          }
                          alt="chat"
                        />
                      </AnimationOnScroll>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-21">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"}
                alt="Bubble"
              />
            </li>

            <li className="shape shape-2">
              <AnimationOnScroll
                animateIn="zoomIn"
                duration={0.25}
                delay={0}
                animateOnce={true}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"}
                  alt="Bubble"
                />
              </AnimationOnScroll>
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-4">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-5">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-6">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-7">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"}
                alt="Bubble"
              />
            </li>
          </ul>
        </div>
      ))}
    </>
  );
};

export default BannerOne;
