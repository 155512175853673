import React from "react";
import PropTypes from "prop-types";

const SEO = ({ title,metaDesc }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="robots" content="index, follow" />
      <meta name="description" content={metaDesc} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
