import React from "react";
import FooterOne from "./common/footer/FooterOne";
import HeaderOne from "./common/header/HeaderOne";
import SEO from "./common/SEO"; //done
import AboutOne from "./component/about/AboutOne"; //done
import BannerOne from "./component/banner/BannerOne"; //done
import BlogOne from "./component/blog/BlogOne";
import BrandOne from "./component/brand/BrandOne";
import CtaLayoutOne from "./component/cta/CtaLayoutOne";
import ServicePropOne from "./component/service/ServicePropOne";
import TestimonialOne from "./component/testimonial/TestimonialOne";
import SectionTitle from "./elements/section-title/SectionTitle";
import ColorSwitcher from "./elements/switcher/ColorSwitcher";
import ProjectTwo from "./component/project/ProjectTwo";
import ProjectThree from "./component/project/ProjectThree";
import { motion } from "framer-motion";

// import { fadeIn, staggerContainer } from "./motion";
function Final() {
  return (
    <>
      <SEO title="Ajitesh Sarangi" metaDesc="Welcome to my portfolio, showcasing expertise in web development, content creation, health & lifestyle. Explore a diverse range of projects, insightful blog posts, and innovative solutions crafted to deliver exceptional results. Stay updated with the latest trends and valuable insights. Let's build something amazing together!" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerOne />
        <div className="section section-padding bg-color-light">
          <div className="container">
            <motion.div
              animate={{ opacity: 1, transition: { duration: 2, delay: 1.2 } }}
              initial={{ opacity: 0 }}
            >
              <SectionTitle
                subtitle="What Work Experience I have"
                title="Companies I have <br> worked with"
                description=""
                textAlignment="heading-left mb--20 mb_md--70"
                textColor=""
              />
            </motion.div>

            <div className="row">
              <ServicePropOne
                colSize="col-lg-4"
                serviceStyle="service-style-2"
                itemShow="3"
                marginTop="yes"
              />
            </div>
          </div>
          <ul className="shape-group-7 list-unstyled">
            <li className="shape shape-1">
              <img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" />
            </li>
            <li className="shape shape-2">
              <img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" />
            </li>
            <li className="shape shape-3">
              <img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" />
            </li>
          </ul>
        </div>
        <AboutOne />
        <ProjectTwo />
        <TestimonialOne />
        <BrandOne />

        <BlogOne />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
}

export default Final;
