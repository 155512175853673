import React, { useState, useEffect } from "react";
import FooterOne from "./common/footer/FooterOne";
import HeaderOne from "./common/header/HeaderOne";
import CtaLayoutOne from "./component/cta/CtaLayoutOne";
import BreadCrumbOne from "./elements/breadcrumb/BreadCrumbOne";
import BlogSidebar from "./component/blog/BlogSidebar";
import BlogGridOne from "./BlogGridOne";
import ColorSwitcher from "./elements/switcher/ColorSwitcher";
import SEO from "./common/SEO";
import { useLocation } from "react-router-dom";

const BlogGridView = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState("");
  const location = useLocation();
  const [searchTitle, setSearchTitle] = useState("");
  const [categoryTitle, setCategoryTitle] = useState(""); 


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("search");
    const categoryQuery = params.get("category");

    if (searchQuery) {
      setSearchTerm(searchQuery);
      setSearchTitle(searchQuery);

      
    } else {
      setSearchTerm(""); 
    }
    if (categoryQuery) {
      setCategory(categoryQuery); 
      setCategoryTitle(categoryQuery);
      
    } else {
      setCategory(""); 
    }
   
  }, [location]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Determine which title to display
  let displayTitle = "All Blogs"; 
  if (searchTitle===searchTerm && searchTitle!=="") {
    displayTitle = `Search results for: "${searchTitle}"`; 
  } else if (categoryTitle===category && categoryTitle!=="") {
    displayTitle = `Category: "${categoryTitle}"`; 
  }
  return (
    <>
      <SEO title="Blogs - Ajitesh Sarangi" metaDesc="Explore diverse range of blogs on health & lifestyle, technology, education, and current events. Fresh perspectives and engaging stories updated regularly." />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title={displayTitle} page="Blog" />
        <div className="section-padding-equal">
          <div className="container">
            <div className="row row-40">
              <div className="col-lg-8">
                <BlogGridOne searchTerm={searchTerm} category={category} />
              </div>
              <div className="col-lg-4">
                <BlogSidebar />
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default BlogGridView;
