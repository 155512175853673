import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

// Home Pages Import
import DigitalAgency from "./pages/DigitalAgency";
import CreativeAgency from "./pages/CreativeAgency";
import PersonalPortfolio from "./pages/PersonalPortfolio";
import HomeStartup from "./pages/HomeStartup";
import CorporateAgency from "./pages/CorporateAgency";

// Blog Import
import BlogGridView from "./BlogGrid";
import BlogCategory from "./pages/Category";
import BlogArchive from "./pages/Archive";
import BlogDetails from "./BlogDetails";

// Service
import ServiceOne from "./ServiceOne";
import ServiceTwo from "./pages/ServiceTwo";
import ServiceDetails from "./pages/ServiceDetails";

// Project
import ProjectGridOne from "./pages/ProjectGridOne";
import ProjectGridTwo from "./pages/ProjectGridTwo";
import ProjectGridThree from "./pages/ProjectGridThree";
import ProjectGridFive from "./pages/ProjectGridFive";
import ProjectGridFour from "./pages/ProjectGridFour";
import ProjectDetails from "./pages/ProjectDetails";

// Pages
import Splash from "./pages/Splash";
import AboutUs from "./pages/AboutUs";
import OurOffice from "./pages/OurOffice";
import OurClients from "./pages/OurClients";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import CaseStudy from "./pages/CaseStudy";
import CaseDetails from "./pages/CaseStudyDetails";
import Testimonials from "./pages/Testimonials";
import PricingTable from "./pages/PricingTable";
import Typography from "./pages/Typography";
import Contact from "./Contact";
import ErrorPage from "./pages/404";
import ComingSoon from "./pages/ComingSoon";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import Sitemap from "./Sitemap";

// Css Import
import "./assets/scss/app.scss";
import Final from "./Final";
import Test from "./Test";
import Test2 from "./Test2";
import TeamOne from "./component/team/TeamOne";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<Final />} exact />
          <Route path={process.env.PUBLIC_URL + "/services"} element={<ServiceOne />} />
          <Route path={process.env.PUBLIC_URL + "/blogpost/:slug"} element={<BlogDetails />} />
          {/* <Route path={process.env.PUBLIC_URL + "/blogpost/:slug"} element={<Test2 />} /> */}
          {/* <Route path={process.env.PUBLIC_URL + "/blogpost"} element={<Test />} /> */}
          <Route path={process.env.PUBLIC_URL + "/blogpost"} element={<BlogGridView />} />
          <Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />} />
          <Route path={process.env.PUBLIC_URL + "/projects"} element={<ProjectGridFour />} />
          <Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={process.env.PUBLIC_URL + "/terms-of-use"} element={<TermsOfUse />} />
          <Route path={process.env.PUBLIC_URL + "*"} element={<ErrorPage />} />
          <Route path={process.env.PUBLIC_URL + "/mentorship"} element={<Team />} />
          <Route path={process.env.PUBLIC_URL + "/download-sitemap-xml"} element={<Sitemap />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
