import React, { useState, useEffect } from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProjectPropTwo from "./itemProp/ProjectPropTwo";
import ProjectData from "../../data/project/ProjectData.json";
import { Link } from "react-router-dom";
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import { motion } from "framer-motion";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}
const portfolioData = ProjectData;

const ProjectTwo = () => {
  // sanity ----
  const [postData, setPostData] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="ui"]{
       link,
        uiImage{
                asset->{
                    _id,
                    url
                }
            },
    }`
      )
      .then((data) => {
        setPostData(data);
      })
      .catch(console.error);
  }, []);
  return (
    <div className="section section-padding-equal bg-color-dark">
      <div className="container">
        <motion.div
          animate={{ opacity: 1, transition: { duration: 2, delay: 1.2 } }}
          initial={{ opacity: 0 }}
        >
          <SectionTitle
            subtitle="Case Study"
            title="Design Concepts"
            description="I have a strong understanding of user-centered design principles and a passion for crafting functional, aesthetically pleasing designs. Dive into my portfolio to discover a vibrant collection of design projects and dynamic branding creations."
            textAlignment="heading-light-left"
            textColor=""
          />
        </motion.div>

        <div className="row row-45">
          {postData.map((data) => (
            <div className="col-md-6" key={data.uiImage.asset._id}>
              <div className={`project-grid project-style-2`}>
                <div className="thumbnail">
                  <a href={data.link}>
                    <img src={urlFor(data.uiImage.asset.url).url()} alt="icon" />
                  </a>
                </div>
                <div className="content"></div>
              </div>
            </div>
          ))}
        </div>
        <div className="more-project-btn">
          <Link to={process.env.PUBLIC_URL + "/projects"} className="axil-btn btn-fill-white">
            Discover More Projects
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectTwo;
